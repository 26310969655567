/** @format */

import React from "react";

const VoterAddress = (props) => {
  const content = props.pdata["data"][0]["col1"]["text"];
  return (
    <div className="row">
      <div className="col voter" dangerouslySetInnerHTML={{ __html: content }} />{" "}
    </div>
  );
};
export default VoterAddress;
