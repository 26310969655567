/** @format */
import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Content = (props) => {
  const content = props.pdata["data"];
  let accItem;
  let accItems = content.map((item, i) => {
    //console.log(item['url']);
    const urlLength = item["url"].length;
    const thumbImage =
      urlLength > 0 ? (
        <img
          src={item["url"]}
          className="float-start me-2 img-fluid rounded-3 thumb-img"
          alt="..."
          key={"imagehk" + i}
        />
      ) : null;
    const mainImage =
      urlLength > 0 ? (
        <img
          src={item["url"]}
          className="col-md-6 float-md-end mb-3 ms-md-3 img-fluid w-100"
          alt="..."
          key={"imagebk" + i}
        />
      ) : null;

    accItem = (
      <React.Fragment>
        <Accordion.Header
          className="accordion-header"
          id={"heading" + i}
          key={"headingk" + i}
        >
          <span>
            {thumbImage}
            <div dangerouslySetInnerHTML={{ __html: item["title"] }} />
          </span>
        </Accordion.Header>
        <div
          id={"collapse" + i}
          key={"collapse1" + i}
          className="accordion-collapse collapse show active"
          aria-labelledby={"heading" + i}
          data-bs-parent="#accordionExample"
        >
          <Accordion.Body
            className="accordion-body clearfix pt-0"
            key={"accbodyk" + i}
          >
            <h6 dangerouslySetInnerHTML={{ __html: item["title"] }} />

            <div dangerouslySetInnerHTML={{ __html: item["text"] }} />
          </Accordion.Body>
        </div>
      </React.Fragment>
    );
    return (
      <Accordion.Item
        className="accordion-item mb-2 accordion-item"
        eventKey={i.toString()}
        key={"itemkey" + i}
      >
        {accItem}
      </Accordion.Item>
    );
  });
  return (
    <div className="accordionContainer">
      <div className="accordion" id="accordionExample">
        <Accordion defaultActiveKey="0">{accItems}</Accordion>
      </div>
    </div>
  );
};
export default Content;
