/** @format */

//import logo from './logo.svg';
import "./styles.css";
import "./quill.css";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Content from "./Content";
import VoterAddress from "./VoterAddress";
import VoterInfo from "./VoterInfo";
import Image from "./Image";
import ImageSlider from "./ImageSlider";
import VideoSlider from "./VideoSlider";
import NotFound from "./notfound";

function DigitalLetter() {
  const { AcNumber } = useParams();
  const { id } = useParams();
  const { preview } = useParams();
  const { part_no } = useParams();
  const { ac_no } = useParams();
  const { sl_no } = useParams();
  const [letterRows, setLetterRows] = useState([]);
  const [noData, setNoData] = useState(false);
  var preview_data;
  console.log();
  if (preview === undefined) {
    preview_data = "active";
    var postData = {
      party_name: process.env.REACT_APP_PARTY_NAME,
      ac_no: parseInt(AcNumber),
      voter_id: id,
      preview: "active",
    };
  } else {
    if (part_no === undefined && sl_no === undefined) {
      var postData = {
        party_name: process.env.REACT_APP_PARTY_NAME,
        ac_no: parseInt(AcNumber),
        voter_id: id,
        preview: "preview",
      };
    } else {
      var postData = {
        party_name: process.env.REACT_APP_PARTY_NAME,
        ac_no: parseInt(AcNumber),
        select_ac_no: parseInt(ac_no),
        part_no: parseInt(part_no),
        slno: parseInt(sl_no),
        preview: "preview",
      };
    }
  }

  const api_url = process.env.REACT_APP_API_URL + `/get/dletter`;
  const getData = useCallback(() => {
    axios({
      method: "POST",
      url: api_url,
      data: postData,
      headers: { tenant: process.env.REACT_APP_TENANT },
    })
      .then((response) => {
        if (response?.data?.rows?.length === 0) {
          setNoData(true);
        } else {
          let tmpRows = response.data.rows;
          tmpRows.sort(function (a, b) {
            return a.sort_order > b.sort_order;
          });
          setLetterRows(tmpRows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      getData();
    });
  }, [getData]);

  return (
    <div className="baseContainer container">
      {noData === false ? (
        <>
          {letterRows.map((letterRow) => {
            return (
              <>
                {/* Build the Header */}
                {letterRow["type"] === "header" ? (
                  <Image pdata={letterRow} />
                ) : null}
                {/* Build the VoterAddress */}
                {letterRow["type"] === "vaddress" ? (
                  <VoterAddress pdata={letterRow} />
                ) : null}
                {/* Build the Content, Voter Info, Video and Image Carousels */}
                <div class="row main g-0">
                  {/* Build the Text */}
                  {letterRow["type"] === "text" ? (
                    <Content pdata={letterRow} />
                  ) : null}
                  {/* Build the Videos */}
                  {letterRow["type"] === "video" ? (
                    <VideoSlider pdata={letterRow} />
                  ) : null}
                  {/* Build the Images */}
                  {letterRow["type"] === "image" ? (
                    <ImageSlider pdata={letterRow} />
                  ) : null}
                </div>
                {/* Build the Voter Info */}
                <div className="p-1 p-sm-0 col-md-3 col-xs-12  d-md-none">
                  {letterRow["type"] === "vinfo" ? (
                    <VoterInfo pdata={letterRow} />
                  ) : null}
                </div>
                {/* Build the Voter Info */}
                <div className="p-1 p-sm-0 d-none d-md-block dlTableData">
                  {letterRow["type"] === "vinfo" ? (
                    <VoterInfo pdata={letterRow} />
                  ) : null}
                </div>
                {/* Build the Footer */}
                {letterRow["type"] === "footer" ? (
                  <Image pdata={letterRow} />
                ) : null}
              </>
            );
          })}

          <br />
        </>
      ) : (
        <NotFound msg="Not Found" />
      )}
    </div>
  );
}

export default DigitalLetter;
